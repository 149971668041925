/* ---------12dec----------- */

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}
.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: end;
}

.items-start {
  align-items: start;
}

.flex-col {
  flex-direction: column;
}

.gap-4 {
  gap: 1rem;
}

section.garage-details {
  padding: 0 1rem !important;
  ul {
    position: relative;
    margin-top: 20px;
    gap: 16px;
    padding-left: 0 !important;
    list-style: none;
    a {
      position: relative;
      font-size: 1rem;
      padding: 0 8px;
    }
    a.active {
      border-bottom: 3px solid #21268e;
      color: #21268e !important;
    }
    &::after {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      background-color: #dee2e6;
      position: absolute;
      bottom: 0;
      z-index: -1;
    }
  }
  .shadow {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .contact-info {
    padding: 40px 20px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #fff;

    .profile {
      width: 100px;
      aspect-ratio: 1/1;
      background-color: #21268e;
      border-radius: 50%;
      border: 6px solid #8dd7f6;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      span {
        font-size: 2rem;
        color: #fff;
      }
    }

    div {
      p {
        font-size: 14px;
        color: rgb(108, 117, 125);
        font-weight: 600;
        margin-bottom: 5px;
      }

      span {
        font-size: 14px;
        color: black;
        margin-bottom: 5px;
        font-weight: 500;
        display: flex;

        .info-icon {
          color: rgb(33, 38, 142);
          margin-right: 10px;
          margin-top: 4px;
        }
      }
      button {
        width: 80px;
        color: #fff;
        background-color: #21268e;
        border: none;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 0;
        margin: 20px auto 0 auto;
      }
    }
  }
  .payment-status {
    border-radius: 12px;
    padding: 20px 40px;
    background: linear-gradient(
      90deg,
      rgba(222, 226, 230, 1) 0%,
      rgba(154, 167, 181, 1) 100%
    );
    display: flex;
    justify-content: space-between;

    div {
      h3 {
        font-size: large;
        color: rgb(33, 38, 142);
        text-align: center;
        margin-bottom: 5px;
        font-weight: 600;
      }
      p {
        font-size: 13px;
        color: black;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }

  .unpaid-invoices {
    background-color: white;
    border-radius: 12px;
    padding: 15px;

    h3 {
      font-size: large;
      color: black;
      font-weight: bold;
      margin-top: 5px;
      margin-bottom: 20px;
    }
    div {
      padding: 0 20px;

      h4 {
        font-size: medium;
        color: black;
        margin-bottom: 5px;
        font-weight: bold;
      }
      p {
        font-size: small;
        color: rgb(52, 52, 52);
        margin-bottom: 0;
      }
      button {
        width: 100%;
        color: #fff;
        background-color: #21268e;
        border: none;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 0;
        margin: 15px 0;
      }
    }
  }

  .saved-cards {
    background-color: white;
    border-radius: 12px;
    padding: 15px;

    div {
      margin-bottom: 20px;

      h3 {
        font-size: large;
        color: black;
        font-weight: bold;
        margin-top: 5px;
      }
      button {
        width: 80px;
        background-color: #fff;
        color: #21268e;
        border: 1px solid #21268e;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 0;
      }
    }
    .master-div {
      border-bottom: 1px solid #dee2e6;
      padding-bottom: 10px;

      img {
        width: 60px;
        margin-right: 10px;
      }
      h5 {
        font-size: medium;
        color: black;
        margin-bottom: 2px;
        font-weight: bold;
      }
      p {
        font-size: small;
        color: rgb(52, 52, 52);
        margin-bottom: 0;
      }
      div {
        margin-bottom: 0;
        div {
          margin-bottom: 0;
          margin-top: 10px;
        }
      }
      .icon-red {
        color: rgb(218, 55, 78);
      }
    }
    .master-div:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .grid-wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1.5rem;
    padding-bottom: 30px;
  }
}

section.table-data {
  .css-1a8ju2e-MuiTableHead-root {
    display: contents !important;
  }
}

@media screen and (max-width: 768px) {
  section.garage-details {
    .grid-wrapper {
      grid-template-columns: 1fr !important;
    }
  }
}

.sign-head {
  color: #202090 !important;
}

.btn-theme {
  background: #202090 !important;
  color: #fff !important;
}

.pass-flex {
  justify-content: space-between;
}

.pass-flex .link-col {
  color: #202090 !important;
  cursor: pointer;
}

.for-pass {
  margin-bottom: 10px !important;
}

.main-modal {
  position: relative;
}

.cross-icn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.reset-card .css-ype7bp {
  padding-top: 24px !important;
}

/* .css-ef811l-MuiListItemIcon-root
{
    background: #202090 !important;
} */
.css-x4ax68 {
  width: 100% !important;
}

/* ---------12dec----------- */

/* ---------7Mar----------- */
.invoice_card {
  height: 100%;
}

.invoice_card .MuiTableContainer-root.css-1yz9zn8-MuiTableContainer-root {
  height: 475px;
  overflow: auto;
}

.css-1gcl85x {
  padding-right: 35px !important;
}

/* ---may-10--- */

.image-upload > input {
  display: none;
}

.image-upload img {
  width: 100px;
  cursor: pointer;
}

h6.custom-upload.text-center.bg-dark {
  height: 30px;
  width: 30px;
  background: #202090;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.css-s46lzk-MuiPaper-root-MuiCard-root {
  height: auto !important;
}

.TableContainer .companylogo {
  width: 30px;
}

.TableContainer .actionIcon {
  width: 30px;
  color: #202090;
}

.fllw_tes,
.iconwrapper {
  display: flex !important;
}

.iconwrapper {
  display: flex !important;
  gap: 5px;
  flex-direction: column;
  margin-left: 50px;
  max-width: 100px;
  padding: 5px;
}

.iconwrapper .icheck-wrap {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dataContainer .css-1g1zbk7-MuiGrid-root,
.spce_top .css-5y6y53-MuiGrid-root {
  padding-top: 0px !important;
}

.css-m1h8jq {
  margin-top: 50px !important;
}

/* ---20May--2023--- */

.Card-title {
  color: #202090 !important;
}

.img-fluid {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  object-fit: cover;
}

.img-fluid.border-radius {
  border-radius: 0.5rem;
}

.Textarea {
  border: 0.0625rem solid #d2d6da;
  outline: none;
  border-radius: 0.5rem;
  padding: 15px;
  width: 100%;
}

.textarea-main {
  width: 100% !important;
}

.card {
  margin-bottom: 20px;
}

.card .cardimage {
  position: relative;
}

.cardimage .upload-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 80px;
  width: 80px;
  opacity: 0;
  transition: 0.5s ease-in-out;
  color: #202090 !important;
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.cardimage:hover .img-fluid {
  opacity: 0.3;
}

.cardimage:hover .upload-btn {
  opacity: 1;
}

/* .TableContainer tbody td.css-n1m04w  span {
    width: max-content!important;
} */

.TableContainer tbody td.css-1gcl85x span {
  width: 100% !important;
}

.css-btbzyb-MuiButtonBase-root-MuiButton-root:focus:not(:hover) {
  background-color: #202090 !important;
  box-shadow: unset !important;
}

.icons-contained {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

input.icon-upload {
  display: none;
}

.previewContainer {
  margin: 1rem;
}

.previewImage {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.icons-contained label {
  height: 30px;
  width: 30px;
  background-color: #202090;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  border-radius: 50%;
}

.TableContainer tbody td .textarea {
  font-size: 16px;
  max-width: 18rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TableContainer tbody td .textarea.client-desc {
  font-size: 16px;
  max-width: 32rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.crd_new {
  padding: 40px 50px;
}

.logo_bmw {
  width: 50px;
}

.rspnv_tbls {
  padding-left: 20px;
}

.roles_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.access_users {
  border: 1px solid #ebebeb;
  padding: 15px 20px;
  border-radius: 6px;
}

.prce_subsptns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex_basis {
  flex: 1;
}

.ul_lists {
  margin-left: 20px;
  font-size: 13px;
  font-weight: 500;
}

.border_list {
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 20px;
}

.border_list li {
  margin-bottom: 8x;
}

.report_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #3447671a !important;
  padding: 15px 30px;
  border-radius: 6px;
  margin-bottom: 20px;
}

.validation_message {
  color: red;
  font-size: small;
}

.passEye {
  position: relative;
}

.passEye svg {
  position: absolute;
  z-index: 1;
  right: 0.5rem;
  top: 0.7rem;
}

/*------------------ car-management --------------- */
.top-btn {
  display: inline-flex;
  gap: 10px;
  width: 12%;
}

.add-modal img {
  width: 100%;
  border-radius: 50%;
}

.add-modal .image {
  width: 150px;
  margin: 0 auto;
}

.add-modal p.modal-title,
.add-modal h3 {
  text-align: center;
  font-size: 18px;
}

.add-modal p {
  margin: 20px 0;
}

.add-modal label.input-file {
  position: absolute;
  left: 53%;
  bottom: 35px;
  background: lightblue;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-modal label.input-file input[type="file"] {
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0;
}

.add-modal .image-wrap {
  position: relative;
}

.add-modal button,
.bulk-modal button {
  width: 100%;
}

button.add-btn,
button.bulk {
  padding: 0;
  height: 0;
  width: 100%;
}

.bulk-upload .dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  height: 200px;
  transition: border 0.24s ease-in-out;
}

.bulk-upload {
  padding: 16px;
  border: 1px solid rgb(232, 232, 232);
  border-radius: 3px;
  width: 100%;
  display: inline-block;
}

.bulk-modal .css-15eaqv5 {
  width: 650px !important;
  padding: 20px !important;
}

.bulk-modal h4,
.bulk-modal li {
  font-size: 14px;
  font-weight: 500;
}

.bulk-modal aside {
  margin-top: 20px;
}

.bulk-modal button {
  margin-top: 20px;
}

.model-name p {
  margin: 0 !important;
}

.insurancePage .top-btn {
  width: 12%;
}

.css-jtlhu6-MuiTablePagination-root .MuiInputBase-root {
  width: 5rem !important;
  max-width: 5rem !important;
}

.MuiTablePagination-root .MuiInputBase-root {
  width: 5rem !important;
  max-width: 5rem !important;
}

.MuiTablePagination-root p {
  margin-bottom: 0 !important;
}

.fltrDrop > button::after {
  display: none;
}

.fltrDrop > button,
.fltrDrop > button:active,
.fltrDrop > button:focus-visible {
  background: #202090 !important;
  border: unset !important;
}

.notificationsWrap {
  padding: 0px 24px;
}

.notificationItem {
  display: flex;
  align-items: start;
  gap: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.0625rem solid #e9ecef;
  margin-bottom: 1rem;
}

.notificationItem .notifyImg {
  width: 36px;
  height: 36px;
  border-radius: 0.8rem;
}

.notificationItem .notifyTitle {
  margin-bottom: 0.2rem;
}

.notificationItem .notifyDesc {
  font-size: 0.85rem;
  margin-bottom: 0.3rem;
}

.notificationItem .notifyTime {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.theme_btn {
  background-color: #202090 !important;
  color: #fff !important;
  width: 100%;
}

.theme_btn.outline {
  color: #202090 !important;
  border: 1px solid #202090 !important;
  background-color: #fff !important;
  width: 100%;
}

.css-wop1k0-MuiDataGrid-footerContainer,
.css-9ffb5l {
  display: none !important;
}

.delete-models {
  width: 2rem !important;
  margin-bottom: 9px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.dashbord-H {
  min-height: 98vh;
  padding: 0px !important;
  /* margin-left: 18.75rem !important; */
}

.css-17po9vf-MuiTypography-root {
  max-width: 300px;
}

.css-1v4tdmd-MuiPaper-root-MuiPopover-paper,
.css-vmkh63-MuiPaper-root-MuiPopover-paper,
.css-eckwqf {
  background-color: white !important;
}

.status-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 4px;
}

.status-icon {
  width: 20px !important; /* Adjust icon size as needed */
  height: 20px !important;
  margin-bottom: 8px; /* Adjust margin between icon and text */
}

.approved-icon {
  fill: #008000; /* Green color for approved */
}

.rejected-icon {
  fill: #ff0000; /* Red color for rejected */
}

.status-text {
  font-size: 14px; /* Adjust text size as needed */
  font-weight: bold;
}

.approved-text {
  color: #008000; /* Green color for approved text */
}

.rejected-text {
  color: #ff0000; /* Red color for rejected text */
}

.notify-items span {
  font-size: 12px;
}

.modal-scrol {
  max-height: 600px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}

.billing-icon {
  font-size: 22px;
  margin-left: 6px;
}

.billing-invoices {
  max-height: 600px;
  overflow-y: auto !important;
  margin: 0 30px;
}

.bar-chart-container .select-bar-chart,
.css-1qlam7x-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background-color: rgb(34, 38, 64) !important;
  height: 36px !important;
  color: white !important;
}

.edit-car .image-wrapper label {
  position: absolute;
  right: 0px;
  bottom: 12px;
  background: lightblue;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1200px) {
  .css-1nrwbza {
    margin-left: 19.75rem !important;
    margin-right: 1rem !important;
  }
}
@media (max-width: 480px) {
  .payment-status {
    flex-direction: column;
    gap: 20px;
  }
}
